<script setup lang="ts">
const target = ref<Element | null>(null);
const animate = ref(false);

const observer = ref<IntersectionObserver | null>(null);
onBeforeMount(() => {
  observer.value = new IntersectionObserver(([entry]) => {
    animate.value = entry.isIntersecting;
  });
});

onMounted(() => {
  if (target.value) observer.value?.observe(target.value);
});

// execute animation for once
watch(animate, (newState) => {
  if (newState === true && target.value)
    observer.value?.unobserve(target.value);
});

const { t } = useI18n({
  useScope: "local",
});
</script>

<template>
  <div ref="target" class="flex flex-col items-center gap-10">
    <h2 class="text-center text-4xl font-bold text-primary">
      {{ t("title") }}
    </h2>
    <div class="mx-auto flex w-full flex-wrap justify-center gap-6">
      <div
        class="flex h-52 w-full flex-col gap-4 rounded-xl bg-surface-container-lowest p-6 outline outline-1 outline-outline/30 sm:h-60 sm:w-[18.75rem]"
        :class="{ animatedSlideBottom: animate }"
      >
        <Icon
          name="ic:round-storefront"
          class="size-8 text-on-surface-variant"
        />
        <span class="text-xl font-semibold text-on-surface/90">{{
          t("pointOfSale")
        }}</span>
        <p class="text-pretty text-base text-on-surface/80 sm:text-balance">
          {{ t("pointOfSaleDescription") }}
        </p>
      </div>
      <div
        class="flex h-52 w-full flex-col gap-4 rounded-xl bg-surface-container-lowest p-6 outline outline-1 outline-outline/30 sm:h-60 sm:w-[18.75rem]"
        :class="{ animatedSlideBottom: animate }"
      >
        <Icon
          name="ic:round-insert-chart"
          class="size-8 text-on-surface-variant"
        />
        <span class="text-xl font-semibold text-on-surface/90">{{
          t("sales")
        }}</span>
        <p class="text-pretty text-base text-on-surface/80 sm:text-balance">
          {{ t("salesDescription") }}
        </p>
      </div>
      <div
        class="flex h-52 w-full flex-col gap-4 rounded-xl bg-surface-container-lowest p-6 outline outline-1 outline-outline/30 sm:h-60 sm:w-[18.75rem]"
        :class="{ animatedSlideBottom: animate }"
      >
        <Icon name="ic:round-category" class="size-8 text-on-surface-variant" />
        <span class="text-xl font-semibold text-on-surface/90">{{
          t("products")
        }}</span>
        <p class="text-pretty text-base text-on-surface/80 sm:text-balance">
          {{ t("productsDescription") }}
        </p>
      </div>
      <div
        class="flex h-52 w-full flex-col gap-4 rounded-xl bg-surface-container-lowest p-6 outline outline-1 outline-outline/30 sm:h-60 sm:w-[18.75rem]"
        :class="{ animatedSlideBottom: animate }"
      >
        <Icon
          name="ic:round-assignment-ind"
          class="size-8 text-on-surface-variant"
        />
        <span class="text-xl font-semibold text-on-surface/90">{{
          t("employees")
        }}</span>
        <p class="text-pretty text-base text-on-surface/80 sm:text-balance">
          {{ t("employeesDescription") }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animatedSlideBottom {
  animation: 1.3s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideBottom;
  animation-delay: 0.1s;
  opacity: 0;
}

@keyframes slideBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Among the features",
    "sales": "Sales & Revenue",
    "salesDescription": "Dilightfull financial statistics for sales and income.",
    "pointOfSale": "Point of sale",
    "pointOfSaleDescription": "Streamline your sales with our amazingly intuitive POS.",
    "products": "Product management",
    "productsDescription": "Enhance your product management experience with us.",
    "employees": "Employee management",
    "employeesDescription": "Effortlessly manage your staff with our user-friendly solution."
  },
  "fr": {
    "title": "Parmi les fonctionnalités",
    "sales": "Ventes & Revenus",
    "salesDescription": "Statistiques financières détaillées pour les ventes et les revenus.",
    "pointOfSale": "Point de vente",
    "pointOfSaleDescription": "Facilitez vos ventes avec notre PDV incroyablement intuitif.",
    "products": "Gestion des produits",
    "productsDescription": "Améliorez votre expérience de gestion des produits avec nous.",
    "employees": "Gestion des employés",
    "employeesDescription": "Facilitez la gestion de votre personnel avec notre solution conviviale."
  }
}
</i18n>
