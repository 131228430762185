<script setup lang="ts">
import { useNuxtApp } from "#app";

const { t } = useI18n({
  useScope: "local",
});

const nuxtApp = useNuxtApp();

// Type guard to check if $img is available and is a function
function isImgFunction(
  value: unknown,
): value is (src: string, options: object) => string {
  return typeof value === "function";
}

// Use a computed property to handle the image URL
const breadImageUrl = computed(() => {
  if (isImgFunction(nuxtApp.$img)) {
    return nuxtApp.$img("/images/bread.png", { width: 500, height: 276 });
  }
  return "/images/bread.png";
});
</script>

<template>
  <section
    class="relative z-10 m-auto flex flex-col items-center justify-center bg-transparent"
  >
    <LandingPageBackground />

    <NuxtImg
      :src="breadImageUrl"
      alt=""
      width="500"
      height="276"
      loading="eager"
      fetchpriority="high"
      class="animated-bread mx-auto"
    />

    <div class="container flex flex-col items-center gap-4 text-center">
      <h1 class="text-4xl font-bold leading-[1.2] text-primary sm:text-5xl">
        {{ t("title") }}
      </h1>
      <p class="max-w-[71ch] text-center text-lg text-on-surface/70 md:text-xl">
        {{ t("description") }}
      </p>
    </div>

    <UILink
      to="/auth/join-us"
      type="button"
      variant="filled"
      :label="t('joinUsBtn')"
      class="animatedBtn mt-10"
      icon="ic:round-handshake"
    />
  </section>
</template>

<style scoped>
.animatedBtn {
  animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 2, 15, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(255, 153, 0, 0);
  }
}

.animated-bread {
  animation:
    1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown,
    3s Infinite linear floating;
  animation-delay: 0s, 1s;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Digital ecosystem. New mentality.",
    "description": "We offer the seller the tools to make his professional life more efficient.",
    "joinUsBtn": "Join Us"
  },
  "fr": {
    "title": "Ecosystème digital. Nouvelle mentalité.",
    "description": "Nous offrons au vendeur les outils pour rendre sa vie professionnelle plus efficace.",
    "joinUsBtn": "Rejoignez-nous"
  }
}
</i18n>
