<script setup lang="ts">
const phoneCookie = useCookie("phone");

const { t } = useI18n({
  useScope: "local",
});
</script>

<template>
  <header
    class="sticky inset-x-0 top-0 z-50 mx-auto flex max-w-screen-xl justify-between px-6 py-4 drop-shadow backdrop-blur-lg xl:px-0"
  >
    <ClientOnly>
      <NuxtLink :to="'/'" class="flex items-center justify-center">
        <NuxtImg
          :src="
            $colorMode.value === 'light'
              ? '/images/makhbazti-logo-name.webp'
              : '/images/logohoriz.png'
          "
          alt="Makhbazti logo"
          class="h-8 w-[150px] object-contain object-left"
          width="707"
          height="145"
          sizes="150px"
          preload
        />
      </NuxtLink>
    </ClientOnly>

    <nav>
      <ul class="shrink-0 space-x-2 p-0 text-sm">
        <li v-if="phoneCookie">
          <UILink
            to="/auth/login"
            type="button"
            variant="filled"
            :label="t('loginBtn')"
          />
        </li>

        <li v-else>
          <UILink
            to="/auth/join-us"
            type="button"
            variant="filled"
            :label="t('joinUsBtn')"
          />
        </li>
      </ul>
    </nav>
  </header>
</template>

<i18n lang="json">
{
  "en": {
    "joinUsBtn": "Join Us",
    "loginBtn": "Login"
  },
  "fr": {
    "joinUsBtn": "Rejoignez-nous",
    "loginBtn": "Se Connecter"
  }
}
</i18n>
